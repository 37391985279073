/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { useLocation, useMatch } from "@reach/router"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import React, { createContext, useState } from "react"
import { animated, config, Transition } from "react-spring"
import Background from "./background"
import Header from "./header"

import "./layout.css"

const PROJECT_COUNT = 3

export const BackgroundContext = createContext()

const Layout = ({ children }) => {
  const [background, setBackground] = useState({
    wireframe: true,
  })

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const matchRoot = useMatch("/")

  const location = useLocation()

  return (
    <BackgroundContext.Provider value={{ background, setBackground }}>
      <Background />

      <Transition
        items={location.pathname}
        from={{ opacity: 0, background: "lightyellow" }}
        enter={{ opacity: 1, background: "transparent" }}
        delay={200}
        config={config.molasses}
        reverse={location.pathname}
      >
        {(styles, item) =>
          item && (
            <animated.div
              key={item}
              style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                overflow: "scroll",
                paddingBottom: matchRoot ? 0 : "40px",
                ...styles,
              }}
            >
              {matchRoot && (
                <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
              )}

              <main
                style={{
                  padding: matchRoot ? 0 : "20px",
                }}
              >
                {!matchRoot && (
                  <button
                    style={{ marginBottom: "20px" }}
                    onClick={() => {
                      navigate("/")
                      setBackground({ wireframe: true })
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        fontFamily: "Roboto",
                      }}
                    >
                      {"<"}
                    </span>
                  </button>
                )}

                {children}
              </main>
            </animated.div>
          )
        }
      </Transition>

      <footer className="footer">
        <h2 style={{ padding: "10px" }}>
          <Link
            to="/"
            style={{
              color: "white",
              textDecoration: `none`,
              textAlign: `center`,
              marginTop: "10px",
            }}
          >
            {data.site.siteMetadata?.title}
          </Link>
        </h2>

        <div className="legal">
          <div>
            <Link style={{ color: "white" }} to="/privacy-policy/">
              Data Privacy
            </Link>
            &nbsp;
            <Link style={{ color: "white" }} to="/impress/">
              Impress
            </Link>
          </div>
          <span style={{ color: "white" }}>
            © {new Date().getFullYear()}, built with
            {` `}
            <a style={{ color: "white" }} href="https://www.gatsbyjs.com">
              Gatsby
            </a>
          </span>
        </div>
      </footer>
    </BackgroundContext.Provider>
  )
}

export default Layout
