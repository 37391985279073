import React, { useContext, useEffect, useRef } from "react"

import GenerativeGraphics from "generative-graphics"
import { BackgroundContext } from "./layout"

const Background = ({ wireframe }) => {
  const { background } = useContext(BackgroundContext)

  const modelRef = useRef()

  useEffect(() => {
    const init = async () => {
      const generativeGraphics = new GenerativeGraphics("default")
      await generativeGraphics.start()

      modelRef.current = generativeGraphics.getModel()

      modelRef.current.set({ wireframe: background.wireframe })
    }

    init()
  }, [])

  useEffect(() => {
    modelRef.current?.set({ wireframe: background.wireframe })
  }, [background])

  return <></>
}

export default Background
